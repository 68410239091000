import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login.vue'
import VueCookies from 'vue-cookies'
// import tool from './module/tool'
var arr = require.context('./module', false, /\.js$/)
var tool = []
arr.keys().forEach(key => {
  const item = arr(key).default
  tool.push(...item)
})
// console.log(arr);
// import store from '../store/index'
Vue.use(VueRouter)
// var menu =
//   localStorage.getItem('menu') && JSON.parse(localStorage.getItem('menu'))
// var items = []
// console.log(menu)
// if (!!menu && menu instanceof Array) {
//   menu.forEach(item => {
//     item.children.forEach(item => {
//       items.push(item)
//     })
//   })
// }
// var itemsval
// if (items.length > 0) {
//   items.forEach(item => {
//     itemsval += '/' + item.name
//   })
// }

// console.log(items[0].name);
// sessionStorage.setItem()
const routes = [
  //   {
  //     path: '*',
  //     redirect: '/home'
  //   },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '扬帆出海后台管理系统' },
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    // redirect: items.length > 0 && '/' + items[0].name,
    component: () => import('../components/home.vue'),
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        meta: { title: '欢迎' },
        component: () => import('../components/welcome.vue')
      },
      {
        path: '/user_list',
        meta: { title: '会员列表' },
        component: () => import('../views/users.vue')
      },

      {
        path: '/activity_add',
        meta: { title: '发布活动' },
        component: () => import('../views/activity/activity_add.vue')
      },
      {
        path: '/activity_list',
        meta: { title: '活动列表', keepAlive: true },
        component: () => import('../views/activity/activity_list.vue')
      },
      {
        path: '/activity_category',
        meta: { title: '商品活动' },
        component: () => import('../views/activity/activity_category.vue')
      },
      {
        path: '/article_list',
        meta: { title: '文章列表' },
        component: () => import('../views/article/wenli.vue')
      },
      {
        path: '/article_add',
        meta: { title: '文章发布' },
        component: () => import('../views/article/Apublishing.vue')
      },
      {
        path: '/article_category',
        meta: { title: '文章分类管理' },
        component: () => import('../views/article/classification.vue')
      },
      {
        path: '/article_check',
        meta: { title: '文章审核' },
        component: () => import('../views/article/shenhe.vue')
      },
      {
        path: '/news_list',
        meta: { title: '快讯列表' },
        component: () => import('../views/news/new_list.vue')
      },
      {
        path: '/system_admin',
        meta: { title: '管理员列表' },
        component: () => import('../views/system_admin.vue')
      },
      {
        path: '/system_frend_link',
        meta: { title: '友情链接' },
        component: () => import('../views/system/system_frend_link.vue')
      },
      {
        path: '/system_config',
        meta: { title: '系统设置' },
        component: () => import('../views/system/system_config.vue')
      },
      {
        path: '/system_seo_config',
        meta: { title: 'seo设置' },
        component: () => import('../views/system/system_seo_config.vue')
      },
      {
        path: '/report_list',
        meta: { title: '报告列表' },
        component: () => import('../views/report/report_list.vue')
      },
      {
        path: '/report_add',
        meta: { title: '报告发布' },
        component: () => import('../views/report/report_add.vue')
      },
      {
        path: '/tag_list',
        meta: { title: '专题管理' },
        component: () => import('../views/article/tag_list.vue')
      },
      {
        path: '/system_partner',
        meta: { title: '合作伙伴' },
        component: () => import('../views/system/system_partner.vue')
      },
      {
        path: '/system_admin_add',
        meta: { title: '新增管理员' },
        component: () => import('../views/system/system_admin_add.vue')
      },
      {
        path: '/system_auth_group',
        meta: { title: '角色管理' },
        component: () => import('../views/system/system_auth_group.vue')
      },
      {
        path: '/system_auth_group_add',
        meta: { title: '新建角色' },
        component: () => import('../views/system/system_auth_group_add.vue')
      },
      {
        path: '/ad_list',
        meta: { title: '广告招募' },
        component: () => import('../views/system/ad_list.vue')
      },
      {
        path: '/home_banner',
        meta: { title: '首页轮换' },
        component: () => import('../views/system/home_banner.vue')
      },
      {
        path: '/home_list',
        meta: { title: '列表' },
        component: () => import('../views/system/home_list')
      },
      {
        path: '/report_log',
        meta: { title: '报告日志' },
        component: () => import('../views/report/report_log.vue')
      },
      {
        path: '/system_hot_word',
        meta: { title: '热门词条' },
        component: () => import('../views/system/system_hot_word.vue')
      },
      {
        path: '/power',
        meta: { title: '权限' },
        component: () => import('../views/system/power.vue')
      },
      {
        path: '/system_logs',
        meta: { title: '系统日志管理' },
        component: () => import('../views/system/system_logs')
      },
      {
        path: '/link_change',
        meta: { title: '链接转换' },
        component: () => import('../views/tool/link_change.vue')
      },
      {
        path: '/ad',
        meta: { title: '广告修改' },
        component: () => import('../views/system/ad.vue')
      },
      // {
      //   path: '/tin',
      //   meta: { title: '广告修改' },
      //   component: () => import('../components/countDowns/show.vue')
      // },
      {
        path: '/user_speaker',
        meta: { title: '演讲嘉宾' },
        component: () => import('../views/guest')
      },
      {
        path: '/guest_edit',
        component: () => import('../views/guest/edit.vue')
      },
      {
        path: '/tin',
        component: () => import('../components/Tinymce')
      },
      // {
      //   path: '/404',
      //   meta: { title: '广告修改' },
      //   component: () => import('../views/system/ad.vue')
      // },
      {
        path: '/user_guest',
        meta: { title: '内部会员', keepAlive: true },
        beforeRouteLeave(to, from, next) {
          to.meta.keepAlive = true
          next(0)
        },
        component: () => import('../views/guest/user_guest.vue')
      },
      {
        path: '/export_user_data',
        meta: { title: '导出数据' },
        beforeRouteLeave(to, from, next) {
          // to.meta.keepAlive = true
          next(0)
        },
        component: () => import('../views/guest/export_user_data.vue')
      },
      {
        path: '/user_guest_edit',
        component: () => import('../views/guest/user_guest_add.vue')
      },
      {
        path: '/wechat_assistant_list',
        meta: { title: '小助手列表' },
        component: () => import('../views/hand/wechat_assistant_list.vue')
      },
      {
        path: '/enterprise_list',
        meta: { title: '企业列表', keepAlive: true },
        component: () => import('../views/enterprise/enterprise_list.vue')
      },
      {
        path: '/cooperation_list',
        meta: { title: '合作列表', keepAlive: true },
        component: () => import('../views/cooperation/cooperation_list.vue')
      },
      {
        path: '/enterprise_add',
        name: 'enterprise_add',
        meta: { title: '新增企业' },
        component: () => import('../views/enterprise/enterprise_add.vue')
      },
      {
        path: '/enterprise_grade_list',
        meta: { title: '企业等级配置' },
        component: () => import('../views/enterprise/enterprise_grade_list.vue')
      },
      {
        path: '/enterprise_grade_list_add',
        name: 'enterprise_grade_list_add',
        meta: { title: '企业号' },
        component: () =>
          import('../views/enterprise/enterprise_grade_list_add.vue')
      },
      {
        path: '/enterprise_banner_check_list',
        name: 'enterprise_banner_check_list',
        meta: { title: '企业广告banner审核' },
        component: () =>
            import('../views/enterprise/enterprise_banner_check_list.vue')
      },
      {
        path: '/user_grade_list',
        name: 'user_grade_list',
        meta: { title: '会员等级列表' },
        component: () => import('../views/user/user_grade_list.vue')
      },
      {
        path: '/user_grade_list_add',
        name: 'user_grade_list_add',
        meta: { title: '会员等级列表' },
        component: () => import('../views/user/user_grade_list_add.vue')
      },
      {
        path: '/cooperation_logs',
        meta: { title: '合作日志' },
        component: () => import('../views/cooperation/cooperation_logs.vue')
      },
      {
        path: '/cooperation_cert_list',
        meta: { title: '合作审核', keepAlive: true },
        component: () =>
          import('../views/cooperation/cooperation_cert_list.vue')
      },
      {
        path: '/enterprise_cert_list',
        meta: { title: '企业认证审核' },
        component: () => import('../views/enterprise/enterprise_cert_list.vue')
      },
      {
        path: '/cooperation_list_add',
        name: 'cooperation_list_add',
        meta: { title: '合作列表' },
        component: () => import('../views/cooperation/cooperation_list_add.vue')
      },
      {
        path: '/user_card_stat',
        meta: { title: '名片日志' },
        component: () => import('../views/user/user_card_stat.vue')
      },
      {
        path: '/user_cert_list',
        meta: { title: '职业认证审核' },
        component: () => import('../views/user/user_cert_list.vue')
      },
      {
        path: '/user_circles_stat',
        meta: { title: '圈子日志' },
        component: () => import('../views/user/user_circles_stat.vue')
      },
      {
        path: '/user_login_log',
        meta: { title: '登录日志' },
        component: () => import('../views/user/user_login_log.vue')
      },
      {
        path: '/order_list',
        meta: { title: '订单列表' },
        component: () => import('../views/order/order_list.vue')
      },
      {
        path: '/order_refund_step1_list',
        meta: { title: '活动退款运营审核' },
        component: () => import('../views/order/order_refund_step1_list.vue')
      },
      {
        path: '/order_refund_step2_list',
        meta: { title: '活动退款财务审核' },
        component: () => import('../views/order/order_refund_step2_list.vue')
      },
      {
        path: '/enterprise_show_info',
        name: 'enterprise_show_info',
        meta: { title: '查看信息' },
        component: () => import('../views/enterprise/show_info.vue')
      },
      {
        path: '/rotary_list',
        name: 'rotary_list',
        meta: { title: '幸运大抽奖' },
        component: () => import('../views/tool/rotary_list.vue')
      },
      {
        path: '/rotary_list_add',
        meta: { title: '幸运大抽奖' },
        component: () => import('../views/tool/rotary_list/add.vue')
      },
      {
        path: '/rotary_list_data',
        meta: { title: '幸运大抽奖' },
        component: () => import('../views/tool/rotary_list/data.vue')
      },
      {
        path: '/cooperation_white_list',
        name: 'cooperation_white_list',
        meta: { title: '合作白名单' },
        component: () =>
          import('../views/cooperation/cooperation_white_list.vue')
      },
      {
        path: '/activity_manager_list',
        name: 'activity_manager_list',
        meta: { title: '助手账号' },
        component: () => import('../views/activity/activity_manager_list.vue')
      },
      {
        path: '/activity_num',
        name: 'activity_num',
        meta: { title: '名单管理' },
        component: () => import('../views/activity/activity_num.vue')
      },
      {
        path: '/activity_channel',
        name: 'activity_channel',
        meta: { title: '渠道管理' },
        component: () => import('../views/activity/activity_channel.vue')
      },
      {
        path: '/activity_authorization',
        name: 'activity_authorization',
        meta: { title: '活动授权' },
        component: () => import('../views/activity/activity_authorization.vue')
      },
      {
        path: '/report_topic',
        name: 'report_topic',
        meta: { title: '报告专题' },
        component: () => import('../views/report/report_topic.vue')
      },
      {
        path: '/bonus_mall',
        name: 'bonus_mall',
        meta: { title: '积分商城' },
        component: () => import('../views/product/bonus_mall.vue')
      },
      {
        path: '/product_add',
        name: 'product_add',
        meta: { title: '添加商品' },
        component: () => import('../views/product/product_add.vue')
      },
      {
        path: '/product_record',
        name: 'product_record',
        meta: { title: '兑换记录' },
        component: () => import('../views/product/product_record.vue')
      },
      {
        path: '/task_rule_list',
        name: 'task_rule_list',
        meta: { title: '任务规则' },
        component: () => import('../views/rule/task_rule_list.vue')
      },
      {
        path: '/task_rule_add',
        name: 'task_rule_add',
        meta: { title: '任务规则' },
        component: () => import('../views/rule/task_rule_add.vue')
      },

      {
        path: '/user_coin_log',
        name: 'user_coin_log',
        meta: { title: '帆币管理' },
        component: () => import('../views/user/user_coin_log.vue')
      },
      {
        path: '/user_bonus_log',
        name: 'user_bonus_log',
        meta: { title: '积分管理' },
        component: () => import('../views/user/user_bonus_log.vue')
      },
      {
        path: '/user_card_list',
        name: 'user_card_list',
        meta: { title: '会员卡' },
        component: () => import('../views/user/user_card_list.vue')
      },
      {
        path: '/user_invitation_log',
        name: 'user_invitation_log',
        meta: { title: '邀请记录' },
        component: () => import('../views/rule/user_invitation_log.vue')
      },
      {
        path: '/clear_cache',
        name: 'clear_cache',
        meta: { title: '清除缓存' },
        component: () => import('../views/system/clear_cache.vue')
      },
      {
        path: '/coupon_list',
        name: 'coupon_list',
        meta: { title: '优惠券',keepAlive: true  },
        component: () => import('../views/tool/coupon_list.vue')
      },

      ...tool,
        {
          path: '/test',
          meta: { title: '测试' },
          component: () => import('../components/map/index.vue')
        },
        {
          path: '/test2',
          meta: { title: '测试' },
          component: () => import('../components/test.vue')
        }
    ]
  }
]

const router = new VueRouter({
  routes
})
// var but=JSON.parse(localStorage.getItem('but')) || []
// but=but.toString()
// but=but+'/home_list' +
// '/ad' +
// '/power' +
// '/guest_edit' +
// '/tin' +
// '/user_guest_edit' +
// '/enterprise_grade_list_add' +
// '/user_grade_list_add' +
// '/cooperation_list_add' +
// '/enterprise_show_info';

// itemsval =
//   itemsval +
//   '/home_list' +
//   '/ad' +
//   '/power' +
//   '/guest_edit' +
//   '/tin' +
//   '/user_guest_edit' +
//   '/enterprise_grade_list_add' +
//   '/user_grade_list_add' +
//   '/cooperation_list_add' +
//   '/enterprise_show_info'
// console.log(itemsval)

router.beforeEach((to, from, next) => {
  // console.log(to.query.phone);
  // console.log(to);
  if (to.path === '/login') {
    return next()
  }
  if (to.meta.title) document.title = to.meta.title
  const tokenstr = VueCookies.get('token')
  if (!tokenstr) return next('/login')
  next()
})

export default router
