<template>
  <div>
    <el-autocomplete
      class="inline-input"
      v-model="state"
      :fetch-suggestions="querySearch"
      placeholder="请输入内容"
      @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'divAutocomplete',
  props: {
    value: {
      type: String,
      default: ''
    },
    selectId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      state:'',
      loading: false,
      addEnData: {
        keyword: '',
        page: 1,
        pageSize: 20
      }
    }
  },
  created() {
    this.state=this.value
  },
  watch: {
    'value'(newValue, oldValue) {
      this.state=this.value
    },
  },
  methods: {
    querySearch(qu, cb) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get('/admin/enterprise/getEnterpriseByName', {
            params: {
              ...this.addEnData,
              keyword: qu
            }
          })
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              var arr = res.data.map(item => {
                return {
                  ...item,
                  value: item.fullName || item.company
                }
              })
              cb(arr)
            }
          })
      } else {
        cb(null)
      }
    },
    handleSelect(item) {
      this.$emit('update:selectId', item.id)
      this.$emit('update:value',item.value)
    }
  },
  computed: {
    state: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.inline-input {
  width: 100%;
}
</style>
