<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="direction"
            :size="'50%'"
            :before-close="handleClose"
        >
            <el-card>
                <el-col :span="10" v-if="interfaces == 7" style="margin-bottom: 10px;">
                    <el-input
                        placeholder="请输入企业全称"
                        v-model="keyword"
                        @keyup.enter.native="searchFirms"
                        class="input-with-select"
                    >
                        <el-button slot="append" icon="el-icon-search" @click="searchFirms"></el-button>
                    </el-input>
                </el-col>
                <slot name="top" :row="123"> </slot>
                <el-table
                    v-if="interfaces == 1"
                    ref="multipleTable"
                    :data="list"
                    style="width: 100%;"
                    border
                    stripe
                    :row-key="
                        (row) => {
                            return row.uid;
                        }
                    "
                    :header-cell-style="
                        () => {
                            return 'background:#F5F7FA;';
                        }
                    "
                    @selection-change="selChange"
                >
                    <el-table-column type="selection" width="55" :reserve-selection="true" />
                    <el-table-column prop="uid" width="100" label="编号" />
                    <el-table-column prop="realName" label="真实姓名" />
                    <el-table-column prop="username" label="用户名" width="180" />
                    <el-table-column prop="phone" label="电话" width="180" />
                </el-table>
                <el-table
                    v-if="interfaces == 2 || interfaces == 7"
                    ref="multipleTable"
                    :data="list"
                    style="width: 100%;"
                    border
                    stripe
                    :row-key="
                        (row) => {
                            return row.id;
                        }
                    "
                    :header-cell-style="
                        () => {
                            return 'background:#F5F7FA;';
                        }
                    "
                    @selection-change="selChange"
                >
                    <template v-if="interfaces == 2">
                        <el-table-column type="selection" width="55" :reserve-selection="true" />
                        <el-table-column prop="id" width="100" label="编号" />
                        <el-table-column prop="name" label="真实姓名" />
                        <el-table-column prop="createTime" label="创建时间" width="180" />
                        <el-table-column prop="phone" label="电话" width="180" />
                    </template>
                    <template v-if="interfaces == 7">
                        <el-table-column prop="" label="名称">
                            <template slot-scope="{ row }">
                                <div v-html="row.name"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="{ row }">
                                <el-button type="text" size="small" @click="addTo(row)">添加</el-button>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>

                <el-pagination
                    v-if="interfaces == 1 || interfaces == 2"
                    background
                    layout="total, prev, pager, next"
                    :current-page.sync="currentPage"
                    :total="total"
                    :page-size="pagesize"
                    @current-change="handleCurrentChange"
                />
                <el-form
                    v-if="interfaces == 3"
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="ruleList"
                    label-width="100px"
                    class="demo-ruleForm"
                    size="small"
                >
                    <el-form-item label="票种名称" prop="name">
                        <el-input v-model="ruleForm.name" maxlength="100" show-word-limit />
                    </el-form-item>
                    <el-form-item label="套票设置">
                        <el-select v-model="ruleForm.type" placeholder="请选择套票">
                            <el-option label="免费票" :value="0" />
                            <el-option label="付费票" :value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="早鸟优惠" v-if="ruleForm.type == 1">
                        <el-radio-group v-model="ruleForm.isDiscount">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">启用</el-radio>
                        </el-radio-group>
                        <div style="height: 10px;"></div>
                        <template v-if="ruleForm.isDiscount == 1">
                            <el-form-item label-width="70px" label="使用条件">
                                在
                                <el-date-picker
                                    size="small"
                                    v-model="ruleForm.discountEndTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    placeholder="选择日期"
                                >
                                </el-date-picker>
                                之前购买
                            </el-form-item>
                            <el-form-item label-width="70px" label="优惠方式">
                                <el-radio-group v-model="ruleForm.discountType">
                                    <el-radio :label="0">折扣减价</el-radio>
                                    <el-radio :label="1">定额减价</el-radio>
                                </el-radio-group>
                                <div style="height: 10px;"></div>
                                每张原价减
                                <el-input style="width:100px" v-model="ruleForm.discountValue" size="small"></el-input
                                >{{ ruleForm.discountType ? "元" : "%" }}
                            </el-form-item>
                        </template>
                    </el-form-item>
                    <el-form-item label="价格（元）" prop="price">
                        <el-input-number
                            v-model="ruleForm.price"
                            :min="0"
                            :precision="2"
                            :disabled="ruleForm.type == 0"
                        />
                    </el-form-item>
                    <!-- <el-form-item label="人均票价" prop="balancePrice">
            <el-input-number
              v-model="ruleForm.balancePrice"
              :min="0"
            ></el-input-number>
          </el-form-item> -->
                    <el-form-item label="数量" prop="number">
                        <!-- <el-input v-model="ruleForm.number"></el-input> -->
                        <el-input-number v-model="ruleForm.number" :min="0" />
                    </el-form-item>
                    <el-form-item label="审核设置" prop="needCheck">
                        <el-select v-model="ruleForm.needCheck" placeholder="请选择套票">
                            <el-option label="需要审核" :value="0" />
                            <el-option label="无需审核" :value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述" prop="desc">
                        <el-input v-model="ruleForm.desc" type="textarea" :rows="2" placeholder="请输入内容" />
                    </el-form-item>
                    <el-form-item label="积分抵扣" v-if="ruleForm.type == 1">
                        <el-radio-group v-model="ruleForm.isUseBonus">
                            <el-radio :label="0">
                                关闭
                            </el-radio>
                            <el-radio :label="1">
                                开启
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="积分权益描述" v-if="ruleForm.isUseBonus == 1">
                        <el-input v-model="ruleForm.useBonusDesc"></el-input>
                    </el-form-item>
                    <el-form-item label="关联票种">
                        <related-ticket-type
                            v-model="ruleForm.refTickets"
                            :initList="ruleForm.refTicketsList"
                        ></related-ticket-type>
                    </el-form-item>
                </el-form>
                <el-form v-if="interfaces == 4" label-position="top" class="theForm">
                    <!-- {{formList}} -->
                    <el-form-item v-for="(item, i) in ruleForm" :key="i" :label="item.name">
                        <template v-if="['file', 'img'].includes(item.type)">
                            <div v-for="(i, n) in item.value" :key="n">
                                <p-image :src="i.url" v-if="item.type == 'img'"> </p-image>
                                <a target="_blank" v-else :href="i.url">{{ i.name }}</a>
                            </div>
                        </template>

                        <!-- <span v-if="item.type == 'file'">{{item.value}}</span> -->
                        <div v-else>{{ item.value }}</div>
                        <!-- {{ruleForm[item.model]}} -->
                    </el-form-item>
                </el-form>
                <el-form
                    v-if="interfaces == 5"
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="ruleList"
                    label-width="100px"
                    class="demo-ruleForm"
                    size="small"
                >
                    <el-form-item label="体验券类型" prop="vcId">
                        <el-radio-group v-model="ruleForm.vcId">
                            <el-radio :label="item.id" v-for="(item, index) in vipList.slice(0, 3)" :key="index">{{
                                item.name
                            }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="体验券名称">
                        <el-col :span="18">
                            <el-input v-model="ruleForm.name" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="体验券天数">
                        <el-col :span="18">
                            <el-input v-model="ruleForm.vipDays" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="有效期">
                        <el-col :span="18">
                            <el-input v-model="ruleForm.expireDays" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="数量">
                        <el-input-number v-model="ruleForm.number" :min="0" />
                    </el-form-item>
                    <el-form-item label="每人限领">
                        <el-input-number v-model="ruleForm.limit" :min="0" />
                        <div class="word-tip">
                            0为不限
                        </div>
                    </el-form-item>
                    <el-form-item label="说明">
                        <el-col :span="18">
                            <el-input
                                type="textarea"
                                :rows="4"
                                v-model="ruleForm.desc"
                                placeholder="请输入内容"
                            ></el-input>
                        </el-col>
                    </el-form-item>
                </el-form>
                <el-form
                    v-if="interfaces == 6"
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="ruleList"
                    label-width="100px"
                    class="demo-ruleForm"
                    size="small"
                >
                    <el-form-item label="名称">
                        <el-input v-model="ruleForm.name" />
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="ruleForm.typeId" placeholder="请选择" @change="ruleForm.businessId = ''">
                            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行业">
                        <el-select v-model="ruleForm.businessId" placeholder="请选择">
                            <el-option v-for="item in businessList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="LOGO">
                        <up-img :action="{ action: 'enterprise' }" :banner.sync="ruleForm.logo"></up-img>
                    </el-form-item>
                    <el-form-item label="描述">
                        <el-input type="textarea" rows="6" v-model="ruleForm.desc"> </el-input>
                    </el-form-item>
                </el-form>
<!--              新增标签 12 -->
              <el-form v-if="interfaces == 12" :model="labelForm" :rules="labelFormRules" ref="labelForm">
                <el-form-item label="类型" label-width="100px" prop="type">
                  <el-select v-model="labelForm.type" placeholder="类型" size="medium" @change="
        labelForm.business ='';">
                    <el-option
                        v-for="(item, index) in typeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="行业"  label-width="100px" prop="business">
                  <el-select v-model="labelForm.business" placeholder="行业"  size="medium">
                    <el-option
                        v-for="(item, index) in businessList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </el-form-item>
<!--               <el-form-item label="二级行业"  label-width="100px" prop="subBusiness">-->
<!--                 <el-select  v-model="labelForm.subBusiness" placeholder="二级行业" size="medium">-->
<!--                   <el-option-->
<!--                       v-for="(item, index) in subBusinessList"-->
<!--                       :key="item.id"-->
<!--                       :label="item.name"-->
<!--                       :value="item.id"-->
<!--                   />-->
<!--                 </el-select>-->
<!--               </el-form-item>-->
                <el-form-item label="企业标签"  label-width="100px" prop="name">
                  <el-input style="width: 215px" v-model="labelForm.name"></el-input>
                </el-form-item>
                <el-form-item label="排序"  label-width="100px">
                  <el-input style="width: 215px" v-model="labelForm.orderNum"></el-input>
                </el-form-item>
<el-button type="primary" style="margin-left:245px" @click="handleEdit">{{labelForm.id?'编辑':'添加'}}</el-button>
              </el-form>
                <el-form
                    v-if="interfaces == 8 || interfaces == 11"
                    ref="ruleForm"
                    class="theForm"
                    :model="ruleForm"
                    :rules="ruleList"
                    label-position="top"
                    label-width="auto"
                >
                    <template v-if="interfaces == 8">
                        <el-form-item label="标题">
                            {{ ruleForm.title }}
                        </el-form-item>
                        <el-form-item label="作者">
                            {{ ruleForm.author }}
                        </el-form-item>
                        <el-form-item label="图片">
                            <p-image :src="ruleForm.picture" width="300px" auto :view="false"></p-image>
                            <!-- <img :src="ruleForm.picture" alt="" style="width:300px;height:auto" /> -->
                        </el-form-item>
                        <el-form-item label="描述">
                            {{ ruleForm.desc }}
                        </el-form-item>
                        <el-form-item label="内容">
                            <div v-html="ruleForm.content"></div>
                        </el-form-item>
                    </template>
                    <el-form-item :label="ruleForm.fileType ? '图片' : '文件'" v-else>
                        <div v-if="ruleForm.fileType">
                            <p-image v-for="item in ruleForm.file" :key="item" :src="item"></p-image>
                        </div>
                        <a v-else target="_blank" :href="$store.getters.imgCol + ruleForm.file">{{ ruleForm.file }}</a>
                    </el-form-item>
                </el-form>
                <template v-if="interfaces == 10">
                    <el-table
                        ref="multipleTable"
                        :data="list"
                        style="width: 100%;"
                        border
                        stripe
                        :row-key="
                            (row) => {
                                return row.id;
                            }
                        "
                        :header-cell-style="
                            () => {
                                return 'background:#F5F7FA;';
                            }
                        "
                        @selection-change="selChange"
                    >
                        <el-table-column prop="name" label="管理员姓名" />
                        <el-table-column prop="phone" label="手机" />

                        <el-table-column prop="createTime" label="签到时间" width="180" />
                    </el-table>
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page.sync="currentPage"
                        :total="total"
                        :page-size="pagesize"
                        @current-change="handleCurrentChange"
                    />
                </template>
                <el-table
                    v-if="interfaces == 9"
                    v-loading="loading"
                    ref="multipleTable"
                    :data="list"
                    style="width: 100%;"
                    border
                    stripe
                    :row-key="
                        (row) => {
                            return row.uid;
                        }
                    "
                    :header-cell-style="
                        () => {
                            return 'background:#F5F7FA;';
                        }
                    "
                    @selection-change="selChange"
                >
                    <el-table-column prop="name" label="产品名称"> </el-table-column>
                    <el-table-column prop="trackName" label="logo">
                        <template #default="{row}">
                            <img style="height: 50px;" :src="row.logo" alt="" />
                        </template>
                    </el-table-column>
                    <el-table-column label="商城" prop="marketName"> </el-table-column>
                    <el-table-column label="国家" prop="country">
                        <template #default="{row}">
                            <el-select v-model="row.countryId">
                                <el-option
                                    :label="item.countryName"
                                    :value="item.countryId"
                                    v-for="(item, index) in row.country"
                                    :key="index"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button type="text" size="small" @click="addTo(row)" v-if="editId">光联</el-button>
                            <el-button type="text" size="small" @click="addTo(row)" v-else>添加</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div
                    v-if="
                        !(
                            interfaces == 4 ||
                            interfaces == 8 ||
                            interfaces == 7 ||
                            interfaces == 9 ||
                            interfaces == 10 ||
                            interfaces == 11 ||
                            interfaces == 12
                        )
                    "
                    style="text-align:right;"
                >
                    <el-button v-if="editId || editId == 0" type="primary" @click="editUser">{{
                        btn ? "修 改" : "loading.."
                    }}</el-button>
                    <el-button v-else type="primary" @click="addUser">{{ btn ? "添 加" : "loading.." }}</el-button>
                </div>
            </el-card>
        </el-drawer>
      <el-dialog
          title="授权过期时间"
          :visible.sync="authAddDialogVisible"
          width="30%"
          center>
        <div>
          <el-radio v-model="authDefaultFrequency" label="0">活动结束7天后过期</el-radio>
          <el-radio v-model="authDefaultFrequency" label="1">自定义权限过期时间</el-radio>
          <div v-if="authDefaultFrequency==1" style="margin-top: 20px;">
            <el-date-picker
                format="yyyy-MM-dd"
                v-model="authEndTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>

          </div>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="authAddDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addAuth">确 定</el-button>
  </span>
      </el-dialog>
    </div>
</template>

<script>
import fuzzySelection from "/src/components/fuzzySelection";
import relatedTicketType from "/src/components/relatedTicketType";
import PImage from "@/components/global/p-image/index.vue";
export default {
    name: "Drawers",
    inject: {
        fthis: { default: "foo" },
    },
    components: {
        PImage,
        fuzzySelection,
        relatedTicketType,
    },
    props: {
        title: {
            type: [String, Object],
            default: "提示",
        },
    },
    data() {
        return {
            authEndTime:'',
            authDefaultFrequency:'0',
            authAddDialogVisible:false,
            drawer: false,
            direction: "rtl",
            list: [],
            total: 0,
            currentPage: 1,
            pagesize: 10,
            btn: true,
            selectTheUser: [],
            interfaces: 1,
            activityId: "",
            ruleForm: {
                name: "",
                type: 0,
                price: "",
                balancePrice: "",
                number: 0,
                needCheck: 0,
                desc: "",
                isDiscount: 0,
                discountEndTime: "",
                discountType: 0,
                discountValue: "",
                limit: 0,
                isUseBonus: 0,
            },
            keyword: "",
            formList: [],
            ruleList: {
                name: [
                    {
                        required: true,
                        message: "请输入票种名称",
                        trigger: "blur",
                    },
                ],
            },
            typeList: [],
            businessList: [],
          subBusinessList:[],
            editId: null,
            vipList: [],
            acId: 0,
            loading: false,
          labelForm:{
              type:'',
            business:'',
            // subBusinesssubBusiness:'',
            name:'',
            orderNum:'',
          },
          labelFormRules: {
            type: [
              { required: true, message: '请选择企业类型', trigger: 'change' }
            ],
            business: [
              {  required: true, message: '请选择行业', trigger: 'change' }
            ],
            // subBusiness: [
            //   {  required: true, message: '请选择二级行业', trigger: 'change' }
            // ],
            name: [
              {required: true, message: '请填写企业标签', trigger: 'change' }
            ],

          }
        };
    },
    watch: {
        drawer(newValue, oldValue) {
            if (this.interfaces == 5 && this.vipList == false) {
                this.getVip();
            }
            if (this.interfaces == 6 && newValue) {
                this.getType();
            }
        },
        "ruleForm.typeId"(news) {
            if (this.ruleForm.businessId == 0) {
                this.ruleForm.businessId = "";
            }
            if (!news) return;
            this.getBus();
        },
      "labelForm.type"(newValue, oldValue) {
        this.getTypeLabel("1");
      },
      "labelForm.business"(newValue, oldValue) {
        this.getTypeLabel("2");
      },
    },
    mounted() {
        // this.getType()
      this.getTypeLabel()
    },
    methods: {
      /**
       * 添加、编辑标签
       */
      async handleEdit(){
        let  isTrue=false
        this.$refs["labelForm"].validate((valid) => {
          if (valid) {
isTrue=true
          } else {
isTrue=false
          }
        });
        if(isTrue){
          if(this.labelForm.id){
            var { data: res } = await this.$http.post(
                '/admin/EnterpriseLabel/editLabel',{...this.labelForm}
            );
          }else {
            var { data: res } = await this.$http.post(
                '/admin/EnterpriseLabel/addLabel',{...this.labelForm}
            );
          }
          this.drawer = false;
          this.$emit("getlist");
          this.labelForm.id=''
          this.labelForm.orderNum=''
          this.$refs['labelForm'].resetFields();
        }
      },
      async getTypeLabel(val) {
        if (val == 1) {
          var { data: res } = await this.$http.get(
              `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.labelForm.type}&businessId=`
          );
          this.businessList = res.data.list;
          return;
        }
        if (val == 2) {
          var { data: res } = await this.$http.get(
              `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.labelForm.type}&businessId=${this.labelForm.business}`
          );
          this.subBusinessList = res.data.list;
          return;
        }
        var { data: res } = await this.$http.get(
            "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
        );
        this.typeList = res.data.list;
      },
       async addAuth(){
          var { data: res } = await this.$http.post("/admin/Activity/activityManagersAdd", {
            managerIds: this.selectTheUser.toString(),
            activityId: this.activityId,
            endTimeType:this.authDefaultFrequency,
            endTime:this.authEndTime
          });
         if (res.errorCode == 200) {
           this.$message.success(res.message);
           this.drawer = false;
           this.authAddDialogVisible=false
           this.authDefaultFrequency='0'
           this.authEndTime=''
           if (this.$refs.multipleTable) {
             this.$refs.multipleTable.clearSelection();
           }
           this.$emit("getlist");
         }
        },
       async init(val) {
            this.drawer = true;
            this.interfaces = val;
         if (val == 12){
           if (this.labelForm.id){
             const { data } = await this.$http.get(
                 '/admin/EnterpriseLabel/getDetailsLabel?id=' + this.labelForm.id
             )
             this.labelForm = {
               ...data.data,
             }
             this.labelForm.type=data.data.type
             this.getTypeLabel(1)
             this.labelForm.business=data.data.business
             this.getTypeLabel(2)
             this.labelForm.subBusiness=data.data.subBusiness
             console.log(data.data)
           }
         }
        },
        async getType() {
            var { data: res } = await this.$http.get(
                "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
            );
            this.typeList = res.data.list;
        },
        async getBus() {
            var { data: res } = await this.$http.get(
                `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.ruleForm.typeId}&businessId=`
            );
            this.businessList = res.data.list;
        },
        async editUser() {
            if (this.ruleForm.id) {
                if (this.interfaces == 5) {
                    var { data: res } = await this.$http.post("/admin/InvitationCoupon/edit", this.ruleForm);
                    this.$message.success(res.message);
                    this.ruleForm = this.$options.data.call(this).ruleForm;
                    this.drawer = false;
                    this.$emit("getlist");
                    return;
                }
                if (this.interfaces == 6) {
                    var { data: res } = await this.$http.post("/admin/EnterpriseCategory/edit", this.ruleForm);
                    this.$message.success(res.message);
                    this.drawer = false;
                    this.$emit("getlist");
                    return;
                }
                var { data: res } = await this.$http.post("/admin/Activity/ticketEdit", this.ruleForm);
                this.$emit("getTicket");
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.drawer = false;
                }
                return;
            }
            this.drawer = false;
            this.$emit("edit", this.ruleForm, this.editId);
        },
        async addUser() {
            if (this.interfaces == 1) {
                var { data: res } = await this.$http.post("/admin/Activity/managerAdd", {
                    amId: this.selectTheUser.toString(),
                });
            } else if (this.interfaces == 2) {
              this.authAddDialogVisible=true
            } else if (this.interfaces == 3) {
                this.$refs["ruleForm"].validate(async (val) => {
                    if (!val) {
                        return;
                    }
                    if (this.activityId) {
                        var { data: res } = await this.$http.post("/admin/Activity/ticketAdd", {
                            ...this.ruleForm,
                            activityId: this.activityId,
                        });
                        if (res.errorCode == 200) {
                            this.$message.success(res.message);
                            this.$emit("getTicket");
                            this.drawer = false;
                        }
                        return;
                    }
                    this.$emit("getlist", {
                        ...this.ruleForm,
                    });
                    // this.ruleForm=this.$data.ruleForm
                    this.ruleForm = this.$options.data.call(this).ruleForm;
                    // return
                    this.drawer = false;
                });
                return;
            } else if (this.interfaces == 5) {
                var { data: res } = await this.$http.post("/admin/InvitationCoupon/add", {
                    ...this.ruleForm,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.$emit("getlist");
                    // this.ruleForm=this.$data.ruleForm
                    this.ruleForm = this.$options.data.call(this).ruleForm;
                    // return
                    this.drawer = false;
                }
                return;
            } else if (this.interfaces == 6) {
                var { data: res } = await this.$http.post("/admin/EnterpriseCategory/add", {
                    ...this.ruleForm,
                });
            }
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.drawer = false;
                if (this.$refs.multipleTable) {
                    this.$refs.multipleTable.clearSelection();
                }
                this.$emit("getlist");
            }
        },
        selChange(val) {
            //   console.log(val)
            if (this.interfaces == 1) {
                this.selectTheUser = val.map((item) => item.uid);
            } else {
                this.selectTheUser = val.map((item) => item.id);
            }
        },
        searchFirms() {
            this.getlist();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getlist();
        },
        async getlist() {
            if (this.interfaces == 1) {
                var { data: res } = await this.$http.get("/admin/AuthMember/getList", {
                    params: {
                        page: this.currentPage,
                        pageSize: this.pagesize,
                    },
                });
            } else if (this.interfaces == 2) {
                var { data: res } = await this.$http.get("/admin/Activity/managerList", {
                    params: {
                        page: this.currentPage,
                        pageSize: this.pagesize,
                    },
                });
            } else if (this.interfaces == 7) {
                var { data: res } = await this.$http.get("/admin/Enterprise/getApiBusinessInfoList", {
                    params: {
                        page: this.currentPage,
                        pageSize: this.pagesize,
                        keyword: this.keyword,
                    },
                });
                if (res.errorCode == 200) {
                    this.list = res.data && res.data.result.items;
                    //   console.log(this.list)
                    return;
                }
            } else if (this.interfaces == 10) {
                var { data: res } = await this.$http.get("/admin/Activity/getSignLogList", {
                    params: {
                        id: this.acId,
                        page: this.currentPage,
                        pageSize: this.pagesize,
                    },
                });
            }

            if (res.errorCode == 200) {
                this.list = res.data.list;
                this.total = res.data.totalCount;
            }
        },
        async addTo(row) {
            if (this.interfaces == 9) {
                if (!row.countryId) return this.$message.error("未选择国家");
                const loading = this.$loading({
                    lock: true,
                    text: "Loading",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                try {
                    if (this.editId) {
                        var { data: res } = await this.$http.post(
                            "/admin/EnterpriseProduct/addJoinProduct",
                            {
                                id: this.editId,
                                joinProduct: JSON.stringify([row]),
                            },
                            {
                                timeout: 300000,
                            }
                        );
                    } else {
                        var { data: res } = await this.$http.post(
                            "/admin/EnterpriseProduct/add",
                            {
                                enterpriseId: this.$route.query.id,
                                appId: row.appId,
                                countryId: row.countryId,
                                marketId: row.marketId,
                            },
                            {
                                timeout: 300000,
                            }
                        );
                    }
                    loading.close();
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.drawer = false;
                        this.$emit("getlist");
                    }
                } catch (error) {
                    loading.close();
                }
                return;
            }
            const { data } = await this.$http.get("/admin/Enterprise/getApiBusinessInfoDetail?id=" + row.id);
            this.drawer = false;
            this.submitInformation(data.data);
        },
        handleClose() {
            if (this.interfaces == 4) {
                this.drawer = false;

                return;
            }
            this.ruleForm = this.$options.data.call(this).ruleForm;
            if (this.$refs.multipleTable) {
                this.$refs.multipleTable.clearSelection();
            }
            this.drawer = false;
            this.labelForm.id=''
            this.labelForm.orderNum=''
            this.$refs['labelForm'].resetFields();
            // this.labelForm={
            //   type:'',
            //   business:'',
            //   subBusiness:'',
            //   name:'',
            //   orderNum:'',
            // }
        },
        async getVip() {
            var { data: res } = await this.$http.get("/api/VipCard/getVipCard");
            //   console.log(res);
            this.vipList = res.data;
        },
        submitInformation(row) {
            this.$emit("sub", row);
        },
    },
    computed: {
        country() {
            return this.fthis.country;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-drawer__body {
    overflow: auto;
}

.theForm {
    /deep/.el-form-item__label {
        // font-weight: blod !important;
        font-weight: 700 !important;
        font-size: 16px;
    }
}
</style>
